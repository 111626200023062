import { BrowserRouter as Router } from 'react-router-dom';
import RoutesComponent from './router/index';
import './App.css';
import './index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LabelProvider } from './utilities/BreadCrumbContext';
import SplashScreen from './components/SplashScreen/SplashScreen';
import { useState, useEffect } from 'react';
import { messaging, getToken, onMessage } from './utilities/firebase';
import { AuthProvider } from './Hooks/authContext';
import mena from './assets/logos/mena.svg';
import notif from './assets/sounds/notif.mp3';
import { useLocation } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';

export const toastMsg = {
  error: function (msg) {
    toast.error(msg, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: '#ef4444',
        color: 'white',
        padding: '16px',
        fontWeight: 'bold',
      },
    });
  },
  warn: function (msg) {
    toast.warn(msg, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: '#FBC02D',
        color: 'white',
        padding: '16px',
        fontWeight: 'bold',
      },
    });
  },
  success: function (msg) {
    toast.success(msg, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: '#22c55e',
        color: 'white',
        padding: '16px',
        fontWeight: 'bold',
      },
    });
  },
};

function App() {
  const [showSplash, setShowSplash] = useState(true);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [logMessages, setLogMessages] = useState([]);
  const location = useLocation();
  const analytics = getAnalytics(); // Initialize Firebase Analytics

  const addLog = (message) => {
    setLogMessages((prevLogs) => [...prevLogs, message]);
  };

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_location: location.pathname,
    });
  }, [location]);

  const requestPermission = async () => {
    if (!('Notification' in window)) {
      addLog('This browser does not support desktop notifications.');
      return;
    }

    // Detect iOS and Safari
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isChromeIOS = navigator.userAgent.includes('CriOS');

    // Handle iOS (Safari and Chrome)
    if (isIOS && (isSafari || isChromeIOS)) {
      addLog(
        'Notifications are not supported on iOS Safari or Chrome. Please add the app to the home screen for push notifications.'
      );
      setShowInstallPrompt(true); // Show prompt to add the app to the home screen
      return;
    }

    // Request notification permission for desktop and other mobile browsers
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const fcmToken = await getToken(messaging, {
          vapidKey:
            'BJADjfDrL-wG86foQrco4Xn5wySL8JRy1VfvkjAoPFHliEJRUxGGZz9QpeMEjMVmCPVfmmcGbyVQToiFvrq9bR8',
        });
        addLog(`FCM Token: ${fcmToken}`);
        localStorage.setItem('fcmToken', fcmToken);
      } else {
        addLog('Permission not granted for notifications.');
      }
    } catch (error) {
      addLog(
        `An error occurred while requesting permission or getting token: ${error}`
      );
    }
  };

  useEffect(() => {
    requestPermission();

    onMessage(messaging, (payload) => {
      addLog(`Message received: ${JSON.stringify(payload)}`);

      if (!('Notification' in window)) {
        addLog('Notifications are not supported in this environment.');
        return;
      }

      const notificationOptions = {
        body: payload.notification.body,
        icon: mena,
        requireInteraction: false,
        silent: true,
      };

      try {
        const notification = new Notification(
          payload.notification.title,
          notificationOptions
        );

        const audio = new Audio(notif);
        audio.play().catch((error) => {
          addLog(`Audio playback prevented due to autoplay policy: ${error}`);
        });

        setTimeout(() => {
          notification.close();
        }, 3000);

        notification.onclick = () => {
          window.focus();
        };
      } catch (error) {
        addLog(`Error showing notification: ${error}`);
      }
    });
  }, []);

  const handleSplashFinish = () => {
    setShowSplash(false);
  };

  const handleClosePrompt = () => {
    setShowInstallPrompt(false);
  };

  return (
    <>
      {showSplash ? (
        <SplashScreen onFinish={handleSplashFinish} />
      ) : (
        <AuthProvider>
          <LabelProvider>
            <RoutesComponent />
          </LabelProvider>
        </AuthProvider>
      )}

      {/* Show prompt for iOS Safari users */}
      {/* {showInstallPrompt && (
        <div className='install-prompt'>
          <div className='install-prompt-content'>
            <p>للحصول على الإشعارات يجب إضافة التطبيق إلى الشاشة الرئيسية:</p>
            <ol>
              <li>
                اضغط على أيقونة <strong>المشاركة</strong> في أسفل المتصفح.
              </li>
              <li>
                اختر <strong>إضافة إلى الشاشة الرئيسية</strong> من القائمة.
              </li>
            </ol>
            <button onClick={handleClosePrompt}>إغلاق</button>
          </div>
        </div>
      )} */}

      <ToastContainer />
    </>
  );
}

export default App;
