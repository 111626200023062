import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const Maps = ({ lat, lon }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAuI5IBE6s_4sSRXTvtF82NoYjOLHbn3Aw',
  });

  const mapContainerStyle = {
    width: '90%',
    height: '250px',
  };
  const center = {
    lat: lat,
    lng: lon,
  };
  const marker = {
    lat: lat,
    lng: lon,
  };

  const handleMapClick = () => {
    const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lon}&z=14`;
    const iosGoogleMapsUrl = `comgooglemaps://?q=${lat},${lon}&zoom=14`;
    const androidIntentUrl = `intent://maps.google.com/maps?q=${lat},${lon}&z=14#Intent;scheme=https;package=com.google.android.apps.maps;end`;

    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isAndroid = /Android/i.test(navigator.userAgent);

    if (isIOS) {
      window.open(googleMapsUrl, '_blank');
    } else if (isAndroid) {
      window.open(googleMapsUrl, '_self');
    } else {
      window.open(googleMapsUrl, '_blank');
    }
  };

  if (!isLoaded) return <div>Loading...</div>;
  //   test

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={14}
      options={{
        disableDefaultUI: true,
      }}
      onClick={handleMapClick}>
      <Marker position={marker} />
    </GoogleMap>
  );
};

export default Maps;
